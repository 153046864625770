import * as anchor from '@project-serum/anchor';
import previewNFT from "./preview.gif"
import logo from "./phloxx_t.png"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MintCountdown } from './MintCountdown';
import { toDate, formatNumber } from './utils';
import { CandyMachineAccount } from './candy-machine';

type HeaderProps = {
  candyMachine?: CandyMachineAccount;
};

export const Header = ({ candyMachine }: HeaderProps) => {
  return (
      <Grid container direction="row" justifyContent="center" wrap="nowrap">
      <Grid container direction="row" wrap="nowrap">
        {candyMachine && (
        <Grid container direction="row" wrap="wrap">
          <Typography style={{ width: '80%'}}></Typography>
          <MintCountdown
          date={toDate(
            candyMachine?.state.goLiveDate
              ? candyMachine?.state.goLiveDate
              : candyMachine?.state.isPresale
              ? new anchor.BN(new Date().getTime() / 1000)
              : undefined,
          )}
          style={{ justifyContent: 'center', width: '20%' }}
          status={
            !candyMachine?.state?.isActive || candyMachine?.state?.isSoldOut
              ? 'COMPLETED'
              : candyMachine?.state.isPresale
              ? 'PRESALE'
              : 'LIVE'
          }
        />
 
          <img src={logo} style={{ width: '25%' }} />
<Typography style={{ width: '10%'}}></Typography>  
          <Typography color="textPrimary" style={{ width: '65%', padding: '1rem 0', fontSize: '48px', fontWeight: 'bold' }}>
                PHLOXX
              </Typography>
<Typography style={{ width: '100%', padding: '1rem 0', fontSize: '32px'}}>JACK - RELEASE #02</Typography>
    <img src={previewNFT} style={{ width: '100%' }} />
              <Typography color="textSecondary" style={{ padding: '1rem 0', fontSize: '20px' }}>
                This is Jack Release #02 - 128 NFTs from a selection of Jack Machine Learning Generated Artwork from the Phloxx Project. There are a total of 65,536 items in the collection which are being released over time for collectors. Visit https://phloxx.ai for more information.
</Typography>
<Typography color="textPrimary" style={{ padding: '1rem 0', fontSize: '32px' }}>
		Remaining: {`${candyMachine?.state.itemsRemaining}`}/128
             </Typography>
	     <Typography color="textPrimary" style={{ padding: '1rem 0', fontSize: '32px' }}>
		Price: {getMintPrice(candyMachine)}
	     </Typography>
        </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const getMintPrice = (candyMachine: CandyMachineAccount): string => {
  const price = formatNumber.asNumber(
    candyMachine.state.isPresale && candyMachine.state.whitelistMintSettings?.discountPrice
      ? candyMachine.state.whitelistMintSettings?.discountPrice!
      : candyMachine.state.price!,
  );
  return `◎ ${price}`;
};
